
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Poppins, Kanit } from 'next/font/google';
import Cookies from 'js-cookie';
import GTMHelper from 'lib/GTMHelper';
import Layout from 'components/Layout';
import ProductTypesContext from 'store/productTypes-context';
import '@styles/globals.css';
import '@styles/sass/main.scss';
export const poppins = Poppins({
    subsets: ['latin'],
    weight: ['400', '700'],
    display: 'swap',
    variable: '--font-poppins',
});
export const kanit = Kanit({
    subsets: ['thai'],
    weight: ['400', '700'],
    display: 'swap',
    variable: '--font-kanit',
});
const getQueryParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return JSON.stringify(params);
};
const getCookieDomain = () => {
    if (!window)
        return;
    const domain = window.location.host;
    const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '';
    return domain.indexOf(cookieDomain) <= 0 ? domain : cookieDomain;
};
const PRODUCT_OVERVIEW_STORAGE_KEY = 'productOverviewUrl';
function MyApp({ Component, pageProps }: any) {
    const { asPath } = useRouter();
    useEffect(() => {
        if (window) {
            const storage = window.localStorage;
            const { hash } = window.location;
            const productOverviewUrl = storage.getItem(PRODUCT_OVERVIEW_STORAGE_KEY);
            if (productOverviewUrl &&
                productOverviewUrl !== asPath &&
                productOverviewUrl !== hash) {
                GTMHelper.customRcHomeCategory('');
                storage.removeItem(PRODUCT_OVERVIEW_STORAGE_KEY);
            }
        }
    }, [asPath]);
    // SET Cookie
    useEffect(() => {
        // Set Cookies
        Cookies.set('lgt_campaign_query_params', getQueryParams(), {
            expires: 3,
            path: '/',
            domain: getCookieDomain(),
        });
        if (!window)
            return;
        Cookies.set('lgt_referer_url', window.location.href, {
            expires: 3,
            path: '/',
            domain: getCookieDomain(),
        });
    }, []);
    const { layout, notifications, popup, settings, page, isMultipleLang, breadcrumbs, } = pageProps;
    return (<div style={{
            fontFamily: `${poppins.style.fontFamily}, ${kanit.style.fontFamily}`,
        }}>
      {layout ? (<Layout layout={layout} notifications={notifications} popup={popup} settings={settings} seo={page?.seo} slug={pageProps?.page?.slug} isMultipleLang={isMultipleLang} staticLinkBuilding={layout.staticLinkBuilding} breadcrumbs={breadcrumbs}>
          <ProductTypesContext.Provider value={pageProps.productTypes}>
            <Component {...pageProps} key={asPath}/>
          </ProductTypesContext.Provider>
        </Layout>) : (<Component {...pageProps} key={asPath}/>)}
    </div>);
}
const __Next_Translate__Page__1931e739c53__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1931e739c53__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  